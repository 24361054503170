import {
  mdiAccountOutline,
  mdiCartOutline,
  mdiFileEditOutline,
  mdiPackageVariantClosed,
  mdiTruckOutline,
} from '@mdi/js'

export default [
  // {
  //   title: 'Dashboard',
  //   icon: mdiHomeVariantOutline,
  //   to: 'home',
  // },
  {
    title: 'RFQ',
    icon: mdiFileEditOutline,
    children: [
      {
        title: 'Lists',
        to: 'rfq',
      },
      {
        title: 'Create',
        to: 'rfq-create',
      },
    ],
  },
  {
    title: 'PO',
    icon: mdiCartOutline,
    to: 'purchase-orders',
  },

  // {
  //   title: 'IAR',
  //   icon: mdiTextBoxSearchOutline,
  //   children: [
  //     {
  //       title: 'Lists',
  //       to: 'iar',
  //     },
  //     {
  //       title: 'Create',
  //       to: 'iar-create',
  //     },
  //   ],
  // },
  {
    title: 'SUPPLIERS',
    icon: mdiTruckOutline,
    children: [
      {
        title: 'Lists',
        to: 'supplier',
      },
      {
        title: 'Create',
        to: 'supplier-create',
      },
    ],
  },
  {
    title: 'ITEMS',
    icon: mdiPackageVariantClosed,
    children: [
      {
        title: 'Lists',
        to: 'items',
      },
      {
        title: 'Create',
        to: 'items-create',
      },
    ],
  },
  {
    title: 'Employees',
    icon: mdiAccountOutline,
    to: 'employees',
  },
]
