import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: to => {
      const userData = JSON.parse(localStorage.getItem('userData'))

      if (userData !== null) return { name: 'home' }

      return { name: 'auth-login', query: to.query }
    },
  },
  {
    path: '/login',
    name: 'auth-login',
    component: () => import('@/views/Login.vue'),
    meta: {
      layout: 'blank',
      resource: 'Public',
      redirectIfLoggedIn: true,
    },
  },
  {
    path: '/rfqs',
    name: 'home',
    component: () => import('@/views/rfq/Index.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/rfqs',
    name: 'rfq',
    component: () => import('@/views/rfq/Index.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/rfqs/create',
    name: 'rfq-create',
    component: () => import('@/views/rfq/Editor.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/rfqs/edit/:id',
    name: 'rfqs-edit',
    component: () => import('@/views/rfq/Editor.vue'),
    meta: {
      layout: 'content',
      navActiveLink: 'rfq',
    },
  },
  {
    path: '/rfq-suppliers/:id/create',
    name: 'rfq-suppliers-create',
    component: () => import('@/views/rfq-suppliers/Editor.vue'),
    meta: {
      layout: 'content',
      navActiveLink: 'rfq',
    },
  },
  {
    path: '/rfq-suppliers/edit/:rfq_supplier_id',
    name: 'rfq-suppliers-edit',
    component: () => import('@/views/rfq-suppliers/Editor.vue'),
    meta: {
      layout: 'content',
      navActiveLink: 'rfq',
    },
  },
  {
    path: '/rfq-suppliers/:id/abstract',
    name: 'rfq-suppliers-abstract',
    component: () => import('@/views/rfq-suppliers/Abstract.vue'),
    meta: {
      layout: 'content',
      navActiveLink: 'rfq',
    },
  },
  {
    path: '/purchase-orders',
    name: 'purchase-orders',
    component: () => import('@/views/purchase-orders/Index.vue'),
    meta: {
      layout: 'content',
    },
  },

  // {
  //   path: '/purchase-orders/create',
  //   name: 'purchase-orders-create',
  //   component: () => import('@/views/purchase-orders/Editor.vue'),
  //   meta: {
  //     layout: 'content',
  //   },
  // },
  {
    path: '/purchase-orders/edit/:id',
    name: 'purchase-orders-edit',
    component: () => import('@/views/purchase-orders/Editor.vue'),
    meta: {
      layout: 'content',
      navActiveLink: 'purchase-orders',
    },
  },
  {
    path: '/supplier',
    name: 'supplier',
    component: () => import('@/views/suppliers/Index.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/supplier/create',
    name: 'supplier-create',
    component: () => import('@/views/suppliers/Editor.vue'),
    meta: {
      layout: 'content',
      navActiveLink: 'supplier',
    },
  },
  {
    path: '/supplier/edit/:id',
    name: 'supplier-edit',
    component: () => import('@/views/suppliers/Editor.vue'),
    meta: {
      layout: 'content',
      navActiveLink: 'supplier',
    },
  },
  {
    path: '/items',
    name: 'items',
    component: () => import('@/views/items/Index.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/items/create',
    name: 'items-create',
    component: () => import('@/views/items/Editor.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/items/edit/:id',
    name: 'items-edit',
    component: () => import('@/views/items/Editor.vue'),
    meta: {
      layout: 'content',
      navActiveLink: 'items',
    },
  },
  {
    path: '/employees',
    name: 'employees',
    component: () => import('@/views/employees/Index.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/employees/create',
    name: 'employees-create',
    component: () => import('@/views/employees/Editor.vue'),
    meta: {
      layout: 'content',
      navActiveLink: 'employees',
    },
  },
  {
    path: '/employees/edit/:id',
    name: 'employees-edit',
    component: () => import('@/views/employees/Editor.vue'),
    meta: {
      layout: 'content',
      navActiveLink: 'employees',
    },
  },
  {
    path: '*',
    redirect: 'error-404',
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
})

// ? Router Before Each hook: https://router.vuejs.org/guide/advanced/navigation-guards.html
router.beforeEach((to, _, next) => {
  const isLoggedIn = localStorage.getItem('accessToken') !== null

  if (!to.meta.redirectIfLoggedIn) {
    // Redirect to login if not logged in
    if (!isLoggedIn) return next({ name: 'auth-login' })
  }

  // Redirect if logged in
  if (to.meta.redirectIfLoggedIn && isLoggedIn) {
    next('/')
  }

  return next()
})

export default router
